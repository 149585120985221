export const educationList = [
    {
        school : "19 MAYIS TURK MAARIF COLLEGE",
        type: "Secondary & High School",
        program : "IGCSE & A Level Science",
        date : "September 2011 - June 2019",
        location : "Kyrenia, Cyprus",
        cgpa : "7.15 / 10.00",
    },
    {
        school : "EASTERN MEDITERRENEAN UNIVERSITY",
        type: "University, Undergraduate",
        program : "BSc in Software Engineering",
        date : "October 2019 - February 2024",
        location : "Famagusta, Cyprus",
        cgpa : "2.89 / 4.00",
    },
    {
        school : "EASTERN MEDITERRENEAN UNIVERSITY",
        type: "University, Postgraduate",
        program : "MSc in Software Engineering with Thesis",
        date : "February 2024 - Present",
        location : "Famagusta, Cyprus",
        cgpa : "4.00 / 4.00",
    }
]