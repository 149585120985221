import React from "react";
import MyImage from "../../assets/myimage.jpg";

function Image() {
  return (
    <div className="my-image lg:mt-16">
      <img
        src={MyImage}
        alt="myimage"
        className="rounded-full lg:w-70 lg:h-70 md:w-65 md:h-65 sm:w-64 sm:h-64 w-40 h-40 mx-auto sm:mt-8 md:mt-12"
      />
    </div>
  );
}

export default Image;